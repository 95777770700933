<template>
  <CRow>
    <vue-confirm-dialog></vue-confirm-dialog>

    <CCol>
      <CCol col="12" lg="6">
        <CCard>
          <CCardBody>
            <CInput
                id="Name"
                label="名前"
                placeholder="名前"
                v-model="shop.name"
                :invalid-feedback="errors && errors.name ? errors.name[0] : ''"
                :is-valid="errors.name ? false : undefined"
            >
            </CInput>
            <CInput
                id="Address"
                label="住所"
                placeholder="住所"
                v-model="shop.address"
                :invalid-feedback="errors && errors.address ? errors.address[0] : ''"
                :is-valid="errors.address ? false : undefined"
            >
            </CInput>
              <CInput
                  id="Tel"
                  label="電話番号"
                  placeholder="電話番号"
                  v-model="shop.tel"
                  :invalid-feedback="errors && errors.tel ? errors.tel[0] : ''"
                  :is-valid="errors.tel ? false : undefined"
              >
            </CInput>
            <CInput
                id="Zipcode"
                label="郵便番号"
                placeholder="郵便番号"
                v-model="shop.zipcode"
                :invalid-feedback="errors && errors.zipcode ? errors.zipcode[0] : ''"
                :is-valid="errors.zipcode ? false : undefined"
            >
            </CInput>
            </CInput>
            <CInput
                id="Rate"
                label="料率"
                placeholder="料率"
                v-model="shop.rate"
                :invalid-feedback="errors && errors.rate ? errors.rate[0] : ''"
                :is-valid="errors.rate ? false : undefined"
            >
            </CInput>
          </CCardBody>
          <CCardFooter>
            <CButton class="mr-3" color="primary" @click="goBack">戻る</CButton>
            <CButton color="success" @click="handleConfirm" >{{$route.params.id ? '更新する' : '追加する'}}</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from "@/api/resourceApi";
import Vue from "vue";

const apiShop = new ResourceApi('shops');

export default {
  name: 'Shop',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.shopsOpened = from.fullPath.includes('shops')
    })
  },
  data () {
    return {
      errors: {},
      updateApi: Function,
      shop: {
        name: '',
        id: this.$route.params.id,
        tel: '',
        address: '',
        zipcode: '',
        rate: '',
      },
    }
  },
  created() {
    this.userData();
  },
  methods: {
    goBack() {
      this.shopsOpened ? this.$router.go(-1) : this.$router.push({path: '/shops'})
    },
    selectShopChange (val) {
      this.user.shop_id =  val.target.value  ? parseInt(val.target.value) : ''
    },

    handleConfirm(){
      const id = this.$route.params.id
      this.$confirm(
          {
            title: '確認',
            message: id ? '更新しますか？' : '作成しますか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.handleUpdateOrCreateShop()
              }
            }
          }
      )
    },
    handleUpdateOrCreateShop() {
      this.loading = true
      let param = this.shop;
      this.confirmModal = true;
      const id = this.$route.params.id
      this.updateApi = apiShop.create;
      if(id){
        this.updateApi = apiShop.edit;
      }
      this.updateApi(param).then(response => {
        Vue.$toast.success('登録成功しました。')
        setTimeout(this.goBack(), 2000);
      }).catch(error => {
        this.errors =  error.response?.data?.errors;
      }).finally(() =>{
        this.loading = false
      })
    },
    userData () {
      const id = this.$route.params.id
      if(!id){
        return
      }
      apiShop.show({id: id}).then(response => {
        this.shop = response.data;
        this.shop.id = this.$route.params.id
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
  }
}
</script>
